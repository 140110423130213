import lodash from 'lodash'
import firebase from '@/firebase'
import store from '@/store'
import localforage from 'localforage';
import moment from 'moment';

let already_listened = {
  user: false,
  wallet: false,
  organization: false,
  company: false,
  adminInfo: false,
  userSchedule: false,
  userFavorites: false,
  userHappiness: false
};

let listeners = {
  user: () => {},
  wallet: () => {},
  organization: () => {},
  company: () => {},
  adminInfo: () => {},
  userSchedule: null,
  userScheduleRef: {
    off: () => {}
  },
  userFavorites: null,
  userFavoritesRef: {
    off: () => {}
  },
  userHappiness: null,
  userHappinessRef: {
    off: () => {}
  }
};

export default {
  organization: async () => {
    if (!already_listened.organization) {
      already_listened.organization = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _organization = await localforage.getItem('organization');

    if (!lodash.isNil(_organization)) {
      store.commit('SET_ORGANIZATION', _organization);
    }

    // Call database
    firebase
      .functions('europe-west2')
      .httpsCallable('SharoAPI')({ action: 'getPublicOrganization', data: {} })
      .then(result => {
        if (result.data.success) {
          _organization = { id: result.data.id, data: result.data.data };
          localforage.setItem('organization', _organization);
          store.commit('SET_ORGANIZATION', _organization);
        }
      });
  },

  company: async () => {
    if (!already_listened.company) {
      already_listened.company = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _company = await localforage.getItem('company');

    if (!lodash.isNil(_company)) {
      store.commit('SET_COMPANY', _company);
    }

    // Call database
    firebase
      .functions('europe-west2')
      .httpsCallable('SharoAPI')({ action: 'getPublicCompany', data: {} })
      .then(result => {
        if (result.data.success) {
          _company = { id: result.data.id, data: result.data.data };
          localforage.setItem('company', _company);
          store.commit('SET_COMPANY', _company);
        }
      });
  },

  user: async () => {
    if (!already_listened.user) {
      already_listened.user = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _user = await localforage.getItem('user');

    if (!lodash.isNil(_user)) {
      store.commit('SET_USER', _user);
    }

    // Call from local db
    listeners.user = firebase
      .firestore()
      .collection('User')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(snap => {
        _user = { id: snap.id, data: snap.data() };
        localforage.setItem('user', _user);
        store.commit('SET_USER', _user);
      });
  },

  wallet: async () => {
    if (!already_listened.wallet) {
      already_listened.wallet = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _wallet = await localforage.getItem('wallet');

    if (!lodash.isNil(_wallet)) {
      store.commit('SET_WALLET', _wallet);
    }

    // Call from local db
    listeners.wallet = firebase
      .firestore()
      .collection('Wallet')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(snap => {
        _wallet = { id: snap.id, data: snap.data() };
        localforage.setItem('wallet', _wallet);
        store.commit('SET_WALLET', _wallet);
      });
  },

  userSchedule: async () => {
    if (!already_listened.userSchedule) {
      already_listened.userSchedule = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _userSchedule = await localforage.getItem('userSchedule');

    if (!lodash.isNil(_userSchedule)) {
      store.commit('SET_USERSCHEDULE', _userSchedule);
    }

    listeners.userScheduleRef = firebase
      .database()
      .ref(`/UserSchedule/${firebase.auth().currentUser.uid}`)
      .orderByKey()
      .startAt(
        moment()
          .utc()
          .format('YYYY-MM-DD')
      )
      .endAt(
        moment()
          .utc()
          .add(7, 'days')
          .format('YYYY-MM-DD')
      );

    listeners.userSchedule = listeners.userScheduleRef.on('value', async userRef => {
      _userSchedule = userRef.val();
      localforage.setItem('userSchedule', _userSchedule);
      store.commit('SET_USERSCHEDULE', _userSchedule);
    });
  },

  userFavorites: async () => {
    if (!already_listened.userFavorites) {
      already_listened.userFavorites = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _userFavorites = await localforage.getItem('userFavorites');

    if (!lodash.isNil(_userFavorites)) {
      store.commit('SET_USERFAVORITES', _userFavorites);
    }
    
    listeners.userFavoritesRef = firebase.database().ref(`/UserFavorites/${firebase.auth().currentUser.uid}`);
    listeners.userFavorites = listeners.userFavoritesRef.on('value', async userFavRef => {
        _userFavorites = userFavRef.val();
        localforage.setItem('userFavorites', _userFavorites);
        store.commit('SET_USERFAVORITES', _userFavorites);
    });
  },

  adminInfo: async () => {

  },

  userHappiness: async () => {
    if (!already_listened.userHappiness) {
      already_listened.userHappiness = true;
    } else {
      return;
    }

    // Save from local db if needed
    let _userHappiness = await localforage.getItem('userHappiness');

    if (!lodash.isNil(_userHappiness)) {
      store.commit('SET_USERHAPPINESS', _userHappiness);
    }
    listeners.userHappinessRef = firebase.database().ref(`/UserHappiness/${firebase.auth().currentUser.uid}`);

    listeners.userHappiness = listeners.userHappinessRef.on('value', async userFavRef => {
        _userHappiness = userFavRef.val();
        localforage.setItem('userHappiness', _userHappiness);
        store.commit('SET_USERHAPPINESS', _userHappiness);
      });
  },

  stop_listeners: () => {
    listeners.user();
    listeners.wallet();
    listeners.organization();
    listeners.company();
    listeners.adminInfo();

    listeners.userHappinessRef.off('value', listeners.userHappiness);
    listeners.userScheduleRef.off('value', listeners.userSchedule);
    listeners.userFavoritesRef.off('value', listeners.userFavorites);
    
    already_listened = {};
  }
};
