import Vue from 'vue';
import Router from 'vue-router';
import Head from 'vue-head';
import { isNil } from 'lodash';
import store from '@/store';

// Home
const Today = () => import('@/views/Home/Today');
const Schedule = () => import('@/views/Schedule');
const Search = () => import('@/views/Search');
const Profile = () => import('@/views/Profile/Profile');
const CarpoolingProfile = () => import('@/views/CarpoolingProfile/CarpoolingProfile');
const ShuttleLiveInfo = () => import('@/views/Shuttle/ShuttleLiveInfo');

const Favorites = () => import('@/views/Favorites.vue');

Vue.use(Router);

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
});

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'today',
      component: Today,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'Home'
      }
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: Schedule,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'Schedule'
      }
    },

    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'Search'
      }
    },

    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'Profile'
      }
    },

    {
      path: '/shuttle-live-info/:id',
      name: 'shuttle_live_info',
      props: true,
      component: ShuttleLiveInfo,
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'ShuttleLiveInfo'
      }
    },

    {
      path: '/shuttle-route/:id',
      name: 'shuttle_route',
      props: true,
      component: () => import('@/views/Shuttle/ShuttleRouteInfo'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'ShuttleRouteInfo'
      }
    },

    {
      path: '/carpooling-profile/:id',
      name: 'carpooling_profile',
      props: true,
      component: CarpoolingProfile,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'CarpoolingProfile'
      }
    },

    {
      path: '/favorites',
      name: 'favorites',
      component: Favorites,
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'Favorites'
      }
    },

    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings/Settings'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'Settings'
      }
    },

    {
      path: '/settings/personal-info',
      name: 'settings_personal_info',
      component: () => import('@/views/Settings/SettingsPersonalInfo'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsPersonalInfo'
      }
    },

    {
      path: '/settings/commuting-info',
      name: 'settings_commuting_info',
      component: () => import('@/views/Settings/SettingsCommutingDetails'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsCommutingInfo'
      }
    },

    {
      path: '/settings/carpooling-info',
      name: 'settings_carpooling_info',
      component: () => import('@/views/Settings/SettingsCarpoolingDetails'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsCarpoolingInfo'
      }
    },

    {
      path: '/settings/carpooling-ticket',
      name: 'settings_carpooling_ticket',
      component: () => import('@/views/Settings/SettingsCarpoolingTicket'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsCarpoolingTicket'
      }
    },

    {
      path: '/settings/change-password',
      name: 'settings_change_password',
      component: () => import('@/views/Settings/SettingsChangePassword'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsChangePassword'
      }
    },

    {
      path: '/settings/change-photo',
      name: 'settings_change_photo',
      component: () => import('@/views/Settings/SettingsChangePhoto'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsChangePhoto'
      }
    },

    {
      path: '/settings/verify-email',
      name: 'settings_verify_email',
      component: () => import('@/views/Settings/SettingsVerifyEmail'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsVerifyEmail'
      }
    },

    {
      path: '/settings/verify-phone-number',
      name: 'settings_verify_phone_number',
      component: () => import('@/views/Settings/SettingsVerifyPhoneNumber'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'SettingsVerifyPhone'
      }
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/User/Login.vue'),
      meta: {
        authRequired: false,
        guestRequired: true,
        fullscreenWrapper: true,
        screenName: 'Login'
      }
    },

    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/User/ResetPassword.vue'),
      meta: {
        authRequired: false,
        guestRequired: true,
        screenName: 'ForgotPassword'
      }
    },

    {
      path: '/_auth',
      name: '_auth',
      component: () => import('@/views/User/AuthHelper.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'AuthHelper'
      }
    },

    {
      path: '/_survey_sandyford',
      name: 'survey_sandyford',
      component: () => import('@/views/User/StartForm/StartForm.vue'),
      meta: {
        authRequired: false,
        guestRequired: true,
        fullscreenWrapper: true,
        screenName: 'SurveySandyford'
      }
    },

    {
      path: '/download',
      name: 'download',
      component: () => import('@/views/Other/DownloadAppPage.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'DownloadApp'
      }
    },

    {
      path: '/profile-verification',
      name: 'profile_verification',
      component: () => import('@/views/Other/ProfileVerifications.vue'),
      meta: {
        authRequired: true,
        guestRequired: false,
        screenName: 'ProfileVerification',
        fullscreenWrapper: true
      }
    },

    {
      path: '/register-carpooling',
      name: 'register_carpooling',
      component: () => import('@/views/User/StartForm/CarpoolingStartForm.vue'),
      meta: {
        authRequired: false,
        guestRequired: true,
        screenName: 'RegisterCarpooling'
      }
    },

    {
      path: '/welcome',
      name: 'welcome_explanation_page',
      component: () => import('@/views/Other/WelcomeExplanationPage.vue'),
      meta: {
        authRequired: true,
        guestRequired: false,
        fullscreenWrapper: true,
        screenName: 'WelcomeExplanationPage'
      }
    },

    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/Other/PrivacyPolicy.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'PrivacyPolity'
      }
    },

    {
      path: '/terms-conditions',
      name: 'terms-conditions',
      component: () => import('@/views/Other/TermsAndConditions.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'TermsConditions'
      }
    },

    {
      path: '/crp',
      name: 'check-carpooling',
      component: () => import('@/views/Other/CheckCarpooling.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'CheckCarpoolingCarRegistration'
      }
    },

    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/Other/FAQ.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'FAQ'
      }
    },


    {
      path: '/safety-social-tips',
      name: 'safety-social-tips',
      component: () => import('@/views/Other/SafetySocialTips.vue'),
      meta: {
        authRequired: false,
        guestRequired: false,
        screenName: 'SafetySocialTips'
      }
    },

    { path: '*', redirect: '/' }
  ]
});

/**
 * Handle user redirections
 */
router.beforeEach(async (to, from, next) => {
  let user = store.getters.userAuth;
  let signedIn = store.getters.signedIn;

  if (!signedIn) {
    user = await store.dispatch('checkUserStatus');
  }

  if (to.meta && to.meta.authRequired && isNil(user)) {
    return next(`/login?redirectUrl=${to.path}`);
  }

  if (to.meta && to.meta.guestRequired && !isNil(user)) {
    return next(`/`);
  }

  // Analytics set screen
  if (to.meta && to.meta.screenName) {
    Vue.prototype.$analytics.setCurrentScreen(to.meta.screenName);
  }

  next();
});

export default router;
