<template>
  <div class="inner-page-title">
    <div @click="goBack" class="back-button">
      <font-awesome-icon v-if="backNavigation" style="color: rgb(100, 100, 100); font-size: 20px" :icon="['fas', 'chevron-left']"></font-awesome-icon>
    </div>

    <div class="title">
      <span>{{ title }}</span>
    </div>

    <div @click="onClickAction" class="action">
      <span>{{ action }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    backNavigation: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      default: ''
    },
    backPath: {
      type: String,
      required: false
    }
  },
  data: () => ({}),
  methods: {
    goBack() {
      if (this.backNavigation) { 
        if (this.backPath) {
          this.$router.push(decodeURI(this.backPath));
        } else {
          this.$router.go(-1);
        }
      }
    },

    onClickAction() {
      if (this.action) {
        return this.$emit('actionClicked', true);
      }
    }
  }
}
</script>

