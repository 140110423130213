var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.signedIn
      ? _c("div", [
          _c(
            "div",
            { staticClass: "mobile-header" },
            [
              _c(
                "router-link",
                {
                  staticClass: "mobile-header-tab",
                  attrs: { to: "/" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { "font-size": "22px" },
                    attrs: { icon: ["fas", "home"] }
                  }),
                  _c("span", [_vm._v("Home")])
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "mobile-header-tab",
                  attrs: { to: "/search" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { "font-size": "22px" },
                    attrs: { icon: ["fas", "search"] }
                  }),
                  _c("span", [_vm._v("Search")])
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "mobile-header-tab",
                  attrs: { to: "/profile" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { "font-size": "22px" },
                    attrs: { icon: ["fas", "user-alt"] }
                  }),
                  _c("span", [_vm._v("My Profile")])
                ],
                1
              ),
              _c(
                "div",
                {
                  class: { "mobile-header-tab": true },
                  on: {
                    click: function($event) {
                      _vm.display_subheader = !_vm.display_subheader
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { "font-size": "22px" },
                    attrs: { icon: ["fas", "ellipsis-h"] }
                  }),
                  _c("span", [_vm._v("More")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              class: {
                "mobile-subheader": true,
                displayed: _vm.display_subheader
              }
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "mobile-subheader-tab",
                  attrs: { to: "/faq" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [_c("span", [_vm._v("FAQ")])]
              ),
              _c(
                "router-link",
                {
                  staticClass: "mobile-subheader-tab",
                  attrs: { to: "/crp" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [_c("span", [_vm._v("Verify carpooler")])]
              ),
              false
                ? _c(
                    "router-link",
                    {
                      staticClass: "mobile-subheader-tab",
                      attrs: { to: "/organization" },
                      nativeOn: {
                        click: function($event) {
                          _vm.display_subheader = false
                        }
                      }
                    },
                    [_c("span", [_vm._v("My company")])]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass: "mobile-subheader-tab",
                  attrs: { to: "/settings" },
                  nativeOn: {
                    click: function($event) {
                      _vm.display_subheader = false
                    }
                  }
                },
                [_c("span", [_vm._v("Settings")])]
              ),
              _c(
                "div",
                {
                  staticClass: "mobile-subheader-tab",
                  on: { click: _vm.logout }
                },
                [_c("span", [_vm._v("Logout")])]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }