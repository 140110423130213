var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar" }, [
    false
      ? _c(
          "span",
          {
            staticClass: "navbar-toggle",
            attrs: { id: "js-navbar-toggle" },
            on: { click: _vm.loadMobile }
          },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "bars"] } })],
          1
        )
      : _vm._e(),
    _c(
      "a",
      {
        staticClass: "logo",
        attrs: { href: "javascript:;" },
        on: {
          click: function($event) {
            _vm.$router.push("/").catch(function() {})
          }
        }
      },
      [_c("img", { attrs: { src: _vm.icon } })]
    ),
    _c("ul", { staticClass: "main-nav", attrs: { id: "js-menu" } }, [
      _vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  _vm.$router.push("/").catch(function() {})
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Home")]
              )
            ]
          )
        : _vm._e(),
      _vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  _vm.$router.push("/schedule").catch(function() {})
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Schedule")]
              )
            ]
          )
        : _vm._e(),
      _vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  _vm.$router.push("/search").catch(function() {})
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Search")]
              )
            ]
          )
        : _vm._e(),
      _vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  _vm.$router.push("/profile").catch(function() {})
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Profile")]
              )
            ]
          )
        : _vm._e(),
      _vm.signedIn()
        ? _c("li", { staticClass: "nav-link-container" }, [
            _c(
              "a",
              { staticClass: "nav-links", attrs: { href: "javascript:;" } },
              [_vm._v("More")]
            ),
            _c("ul", { staticClass: "submenu" }, [
              false
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            _vm.$router
                              .push("/organization")
                              .catch(function() {})
                          }
                        }
                      },
                      [_vm._v("Company")]
                    )
                  ])
                : _vm._e(),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        _vm.$router.push("/crp").catch(function() {})
                      }
                    }
                  },
                  [_vm._v("Verification")]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        _vm.$router.push("/faq").catch(function() {})
                      }
                    }
                  },
                  [_vm._v("FAQ")]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        _vm.$router.push("/settings").catch(function() {})
                      }
                    }
                  },
                  [_vm._v("Settings")]
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Logout")]
              )
            ]
          )
        : _vm._e(),
      !_vm.signedIn()
        ? _c(
            "li",
            {
              staticClass: "nav-link-container",
              on: {
                click: function($event) {
                  _vm.$router.push("/login").catch(function() {})
                }
              }
            },
            [
              _c(
                "a",
                { staticClass: "nav-links", attrs: { href: "javascript:;" } },
                [_vm._v("Login")]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }