<template>
  <div v-loading.fullscreen.lock="!(loaded || !signedIn)" id="app">
    <nav-bar v-if="!$route.meta.fullscreenWrapper"></nav-bar>
    <div v-if="loaded || !signedIn" :class="{ 'main-wrapper': true, 'fullscreen-main-wrapper': $route.meta.fullscreenWrapper }">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <mobile-nav-bar v-if="signedIn && !$route.meta.fullscreenWrapper"></mobile-nav-bar>
  </div>
</template>
<script>
import NavBar from '@/components/NavBars/NavBar'
import MobileNavBar from '@/components/NavBars/MobileNavBar'
import firebase from '@/firebase';
import localforage from 'localforage';

export default {
  components: { NavBar, MobileNavBar },
  data: () => ({
    number_of_app_opens: 0,
  }),
  methods: {
    async turnOnNotification() {
      const uid = firebase.auth().currentUser.uid;
      const messaging = firebase.messaging();

      try {
        let permission = await Notification.requestPermission();

        if (permission != 'granted') { return permission };

        await this.updateToken();

        messaging.onTokenRefresh(_ => this.updateToken());

      } catch (err) {
        console.error(err);
        if (err.code === "messaging/token-unsubscribe-failed") {
          this.turnOnNotification();
        }

        return;
      } 
    },

    async updateToken() {
      const token = await firebase.messaging().getToken();
        
      // send to server
      console.log(token);
      firebase.database().ref(`UserNotificationDeviceToken/${firebase.auth().currentUser.uid}/${localStorage.getItem('deviceID')}`).set(token);
    },

    showWelcomePageIfNeeded() {
      if (this.number_of_app_opens < 2) {
        this.$router.push('/welcome');
      }
    },

    async logNumberOfOpens() {
      this.number_of_app_opens = await localforage.getItem('numberOfAppOpens');
      this.number_of_app_opens = this.number_of_app_opens + 1;
      await localforage.setItem('numberOfAppOpens', this.number_of_app_opens);
    }
  },
  computed: {
    signedIn() {
      return this.$store.getters.signedIn;
    },

    loaded() {
      return this.$store.getters.loaded;
    }
  },

  watch: {
    signedIn: {
      handler(val) {
        if (process.env.NODE_ENV != 'production') {
          return;
        }

        // If firebase messaging is not supported
        if (!firebase.messaging.isSupported) {
          return;
        }

        // Get last time we asked for notifications
        let lastAskedNotifications = JSON.stringify(localStorage.getItem('lastAskedNotifications'));

        // If we never asked for notifications, set to ask in 12 hours
        if (!lastAskedNotifications) {
          lastAskedNotifications = Date.now() - (6 * 24 * 60 * 60 * 1000) - (12 * 60 * 60 * 1000);
          localStorage.setItem('lastAskedNotifications', lastAskedNotifications);
        }
      

        // Ask for notification
        if (val) {
          // alert(Notification.permission);
          if (Notification.permission === 'default' && (!lastAskedNotifications || lastAskedNotifications < (Date.now() - (7 * 24 * 60 * 60 * 1000)))) {
            // Save the last time we asked for notifications
            localStorage.setItem('lastAskedNotifications', Date.now());

            this.$confirm('We would like to send you notifications in the future to improve your experience.', 'Can we send you notifications?', {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              // type: 'info'
            }).then(() => {
              this.turnOnNotification();
            }).catch(() => {});
          } else if (Notification.permission === 'granted') {
            this.turnOnNotification();
          }
        }
      },
      immediate: true
    }
  },

  async mounted() {
    const self = this;

    this.$firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // Log number of times opened
        await this.logNumberOfOpens();
    
        // If first time opening app, show welcome page
        this.showWelcomePageIfNeeded();
      }
    });


    // Messaging
    if (firebase.messaging.isSupported) {
      firebase.messaging().onMessage(payload => {
        this.$notify({
           title: payload.data.title,
           message: payload.data.body,
           type: payload.data.type || 'info',
           duration: 10000,
           onClick() {
             if (payload.data.click_action) {
               self.$router.push(payload.data.click_action);
             }
           }
        });
      });
    }
  }
}
</script>


<style lang="scss" scoped>
  .fullscreen-main-wrapper {
    padding: 0;
    height: 100%;
    min-height: 100vh;
  }
</style>

