var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { "body-style": _vm.bodyStyle, shadow: _vm.shadow || "always" }
        },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.action
                ? _c("div", { staticClass: "card-action" }, [
                    _vm.action && _vm.action.length < 1
                      ? _c(
                          "div",
                          {
                            staticClass: "card-action-click",
                            on: { click: _vm.onActionClick }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "el-dropdown-link" },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "icon-clickable",
                                  style: _vm.actionColor
                                    ? {
                                        color: _vm.actionColor,
                                        "font-size": "16px"
                                      }
                                    : { "font-size": "16px" },
                                  attrs: {
                                    icon: _vm.icon || ["fas", "ellipsis-h"]
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm.action
                      ? _c(
                          "div",
                          { staticClass: "card-action-click" },
                          [
                            _c(
                              "el-dropdown",
                              {
                                attrs: {
                                  trigger: "click",
                                  placement: "bottom-start"
                                },
                                on: { command: _vm.onActionClick }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "icon-clickable",
                                      style: _vm.actionColor
                                        ? {
                                            color: _vm.actionColor,
                                            "font-size": "16px"
                                          }
                                        : { "font-size": "16px" },
                                      attrs: {
                                        icon: _vm.icon || ["fas", "ellipsis-h"]
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown"
                                  },
                                  _vm._l(_vm.action, function(dropdown) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: dropdown.value,
                                        attrs: {
                                          command: dropdown.value,
                                          icon: dropdown.icon
                                        }
                                      },
                                      [_vm._v(_vm._s(dropdown.label))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.title
                ? _c(
                    "h3",
                    { staticClass: "card-title", style: _vm.titleStyle },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }