<template>
  <div class="card">
    <el-card :body-style="bodyStyle" :shadow="shadow || 'always'" v-loading="loading">
      <div class="card-body">
        <div v-if="action" class="card-action">
          <div v-if="action && action.length < 1" class="card-action-click" @click="onActionClick">
            <span class="el-dropdown-link">
              <font-awesome-icon
                class="icon-clickable"
                :style="actionColor ? {color: actionColor, 'font-size': '16px'} : {'font-size': '16px'}"
                :icon="icon || ['fas', 'ellipsis-h']"
              />
            </span>
          </div>

          <!-- If dropdown -->
          <div v-else-if="action" class="card-action-click">
            <el-dropdown trigger="click" placement="bottom-start" @command="onActionClick">
              <span class="el-dropdown-link">
                <font-awesome-icon
                  class="icon-clickable"
                  :style="actionColor ? {color: actionColor, 'font-size': '16px'} : {'font-size': '16px'}"
                  :icon="icon || ['fas', 'ellipsis-h']"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="dropdown in action"
                  :key="dropdown.value"
                  :command="dropdown.value"
                  :icon="dropdown.icon"
                >{{ dropdown.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <h3 v-if="title" :style="titleStyle" class="card-title">{{ title }}</h3>
        <slot></slot>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    cardId: String,
    shadow: String,
    icon: Array,
    action: Array,
    actionColor: String,
    title: String,
    bodyStyle: Object,
    titleStyle: Object,
    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onActionClick(command) {
      this.$emit('clicked', this.cardId, command);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  position: relative;
}

.card-title {
  font-weight: 400;
  margin-bottom: 15px;
}

.card-action {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px 0 5px;
}

.card-action-click:hover {
  cursor: pointer;
}
</style>