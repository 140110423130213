<template>
  <nav class="navbar">
    <span v-if="false" class="navbar-toggle" @click="loadMobile" id="js-navbar-toggle">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </span>
    <a href="javascript:;" @click="$router.push('/').catch(()=>{})" class="logo">
      <img :src="icon" />
    </a>
    <ul class="main-nav" id="js-menu">
      <!-- Signed In -->
       <li
        class="nav-link-container"
        @click="$router.push('/').catch(()=>{})"
        v-if="signedIn()"
      >
        <a href="javascript:;" class="nav-links">Home</a>
      </li>

      <li
        class="nav-link-container"
        @click="$router.push('/schedule').catch(()=>{})"
        v-if="signedIn()"
      >
        <a href="javascript:;" class="nav-links">Schedule</a>
      </li>

      <li
        class="nav-link-container"
        @click="$router.push('/search').catch(()=>{})"
        v-if="signedIn()"
      >
        <a href="javascript:;" class="nav-links">Search</a>
      </li>

      <li
        class="nav-link-container"
        @click="$router.push('/profile').catch(()=>{})"
        v-if="signedIn()"
      >
        <a href="javascript:;" class="nav-links">Profile</a>
      </li>

      <li class="nav-link-container" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">More</a>
        <ul class="submenu">
          <li v-if="false">
            <a href="javascript:;" @click="$router.push('/organization').catch(()=>{})">Company</a>
          </li>

          <li>
            <a href="javascript:;" @click="$router.push('/crp').catch(()=>{})">Verification</a>
          </li>

          <li>
            <a href="javascript:;" @click="$router.push('/faq').catch(()=>{})">FAQ</a>
          </li>

          <li>
            <a href="javascript:;" @click="$router.push('/settings').catch(()=>{})">Settings</a>
          </li>
          
        </ul>
      </li>


      <li class="nav-link-container" @click="logout()" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">Logout</a>
      </li>

      <!-- Not Signed In -->
      <li
        class="nav-link-container"
        @click="$router.push('/login').catch(()=>{})"
        v-if="!signedIn()"
      >
        <a href="javascript:;" class="nav-links">Login</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { setTimeout } from 'timers';
export default {
  data() {
    return {};
  },

  computed: {
    icon() {
      return require('@/assets/img/logo-black.png');
    }
  },

  methods: {
    loadMobile(e) {
      document.getElementById('js-navbar-toggle').classList.toggle('active');
      document.getElementById('js-menu').classList.toggle('active');
    },

    logout() {
      this.$store.dispatch('logout').then(result => {
        this.$router.push('/login').catch(()=>{});
      });
    },

    signedIn() {
      return this.$store.getters.signedIn;
    }
  },

  mounted() {}
};
</script>