var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: !(_vm.loaded || !_vm.signedIn),
          expression: "!(loaded || !signedIn)",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "app" }
    },
    [
      !_vm.$route.meta.fullscreenWrapper ? _c("nav-bar") : _vm._e(),
      _vm.loaded || !_vm.signedIn
        ? _c(
            "div",
            {
              class: {
                "main-wrapper": true,
                "fullscreen-main-wrapper": _vm.$route.meta.fullscreenWrapper
              }
            },
            [
              _c(
                "transition",
                { attrs: { name: "slide-fade", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.signedIn && !_vm.$route.meta.fullscreenWrapper
        ? _c("mobile-nav-bar")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }