import firebase from '@/firebase'
import { cloneDeep } from 'lodash'

const state = {
  userAuth: {},
  userClaims: {},
  shortId: Math.random().toString(36).substr(2, 256)
}

const actions = {
  login({ commit, state }, { email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(userAuth => {
          commit('SET_USER_AUTH', userAuth)
          resolve(userAuth)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({ commit, state }) {
    firebase.database().ref(`UserNotificationDeviceToken/${firebase.auth().currentUser.uid}/${localStorage.getItem('deviceID')}`).remove();
    
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit('LOGOUT')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkUserStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(userAuth => {
        if (userAuth) {
          commit('SET_USER_AUTH', userAuth)
          resolve(userAuth)
        } else {
          resolve(userAuth)
        }
      })
    })
  },

  checkUserClaims({ commit, state }, force = false) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdTokenResult(force)
        .then(result => {
          commit('SET_USER_CLAIMS', result.claims)
          resolve(result.claims)
        })
        .catch(() => {
          reject()
        })
    })
  },

  regenerateShortId({ commit }) {
    commit('SET_SHORT_ID', Math.random().toString(36).substr(2, 256));
  }
}

const mutations = {
  LOGOUT(_state, _user) {
    _state.userAuth = {};
  },
  SET_USER_AUTH(_state, _user) {
    _state.userAuth = cloneDeep(_user);
  },
  SET_USER_CLAIMS(_state, _claims) {
    _state.userClaims = cloneDeep(_claims);
  },
  SET_SHORT_ID(_state, _short_id) {
    _state.shortId = _short_id;
  }
}

const getters = {
  userAuth: state => {
    return state.userAuth;
  },
  userClaims: state => {
    return state.userClaims;
  },

  signedIn: _state => {
    return Boolean(_state.userAuth.uid);
  },
  shortId: _state => {
    return _state.shortId;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
