var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inner-page-title" }, [
    _c(
      "div",
      { staticClass: "back-button", on: { click: _vm.goBack } },
      [
        _vm.backNavigation
          ? _c("font-awesome-icon", {
              staticStyle: { color: "rgb(100, 100, 100)", "font-size": "20px" },
              attrs: { icon: ["fas", "chevron-left"] }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))])
    ]),
    _c("div", { staticClass: "action", on: { click: _vm.onClickAction } }, [
      _c("span", [_vm._v(_vm._s(_vm.action))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }