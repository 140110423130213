<template>
  <div :style="`display: block; margin-top: ${space}`"></div>
</template>

<script>
export default {
  props: {
    space: { type: String, default: "0px" }
  }
}
</script>
