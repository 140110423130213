import { cloneDeep } from 'lodash';
import localforage from 'localforage';
import firebase from '@/firebase';
import mm from "@/utils/mixin_methods";

const state = {
  organization: {
    data: {}
  },
  user: {
    data: {
      carpooling_friends: {}
    }
  },
  wallet: {
    data: {}
  },
  company: {
    data: {}
  },
  userSchedule: {},
  userHappiness: {},
  userFavorites: {},
  adminInfo: {},
  loaded: {
    organization: false,
    user: false,
    company: false
  },
  carpooling_friends: [],
  ip_address: '',
  ip_information: {}
};

const actions = {
  async getCarpoolingFriends({ commit }) {
    let result;

    try {
      result = await firebase.functions('europe-west2').httpsCallable('SharoAPI')({ action: 'getCarpoolingFriends', data: {} });
    } catch (err) {
      console.error("Internal error, cannot get carpooling friends");
      return { error: true, err: err, error_message: "Internal error, cannot get carpooling friends" };
    }
    
    if (result.data.error) {
      console.error("Error, cannot get carpooling friends");
      console.log(result);
      return result.data;
    }

    commit('SET_CARPOOLING_FRIENDS', result.data.friends);

    return result.data;
  },

  async updateProfileAuth({ commit }) {
    let result;

    try {
      result = await firebase.functions('europe-west2').httpsCallable('SharoAPI')({ action: 'updateUserProfile', data: {} });
    } catch (err) {
      console.error(err);
    }
  },

  async getIPInformation({ commit }) {
    try {
      let ip = await mm.getIPAddress();

      if ('ip' in ip) {
        commit('SET_IP_ADDRESS', ip.ip);
      } else {
        console.error('Cannot get IP', ip);
        return;
      }

      // If they had requested ip less than a week ago
      if (localStorage.getItem('IPInformation') && mm.daysAgo(JSON.parse(localStorage.getItem('IPInformation')).last_asked) < 7) {
        commit('SET_IP_INFORMATION', JSON.parse(localStorage.getItem('IPInformation')).data);
        return;
      }

      let result = await firebase.functions('europe-west2').httpsCallable('SharoAPI')({ action: 'getIPInformation', data: { ip: ip.ip } });

      if (result.data.error) {
        console.error('Cannot get IP', result);
        return;
      }

      commit('SET_IP_INFORMATION', result.data.result);

      localStorage.setItem('IPInformation', JSON.stringify({ last_asked: Date.now(), data: result.data.result }));

    } catch (err) {
      console.error('Cannot get IP', err);
    }
  }
};

const mutations = {
  SET_ORGANIZATION(_state, _org) {
    _state.loaded.organization = true;
    _state.organization = cloneDeep(_org);
  },

  SET_USER(_state, _user) {
    _state.loaded.user = true;
    _state.user = cloneDeep(_user);
  },

  SET_WALLET(_state, _wallet) {
    _state.wallet = cloneDeep(_wallet);
  },

  SET_COMPANY(_state, _company) {
    _state.loaded.comapny = true;
    _state.company = cloneDeep(_company);
  },

  SET_USERSCHEDULE(_state, _schedule) {
    _state.userSchedule = cloneDeep(_schedule);
  },

  SET_USERHAPPINESS(_state, _userHappiness) {
    _state.userHappiness = cloneDeep(_userHappiness);
  },

  SET_USERFAVORITES(_state, _userFavorites) {
    _state.userFavorites = cloneDeep(_userFavorites);
  },

  SET_ADMININFO(_state, _adminInfo) {
    _state.adminInfo = cloneDeep(_adminInfo);
  },

  SET_CARPOOLING_FRIENDS(_state, _friends) {
    _state.carpooling_friends = cloneDeep(_friends);
  },

  SET_IP_ADDRESS(_state, _ip) {
    _state.ip_address = cloneDeep(_ip);
  },

  SET_IP_INFORMATION(_state, _ip_info) {
    _state.ip_information = cloneDeep(_ip_info);
  }
};

const getters = {
  organization: _state => {
    return _state.organization;
  },

  user: _state => {
    return _state.user;
  },

  wallet: _state => {
    return _state.wallet;
  },

  company: _state => {
    return _state.company;
  },

  userSchedule: _state => {
    return _state.userSchedule;
  },

  userHappiness: _state => {
    return _state.userHappiness;
  },

  userFavorites: _state => {
    return _state.userFavorites;
  },

  adminInfo: _state => {
    return _state.adminInfo;
  },

  loaded: _state => {
    return _state.loaded.user && _state.loaded.organization;
  },

  carpooling_friends: _state => {
    return _state.carpooling_friends;
  },

  ip_address: _state => {
    return _state.ip_address;
  },

  ip_information: _state => {
    return _state.ip_information;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
